* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.logo {
  width: 117px;
  height: 36px;
  margin-left: 10px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 5px; */
  height: 80px;
  border-bottom: 0.2px solid #e5e7eb;
}
.main-header {
  position: fixed;
  width: 220px;
  height: 100vh;
  background: #f9f7f3ff;
  z-index: 100;
  transition: all 0.5s ease;
}
.active {
  width: 50px;
  z-index: -1;
}
.main-header .navbar h1 {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.2px solid #354f52;
}
.main-header .navbar ul li {
  list-style: none;
  display: relative;
  height: 50px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-header .navbar ul li a.act,
.main-header .navbar ul li a:hover {
  background: #354f52;
  color: #fff;
}
.main-header .navbar ul li a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  color: #000;
  transition: all 0.4s ease;
}

.main-header .navbar ul li a i {
  min-width: 50px;
  text-align: center;
  font-size: 18px;
  color: #f7a072ff;
}

/* .main-header .navbar ul li a.act,
.main-header .navbar ul li a i:hover {
  color: #fff;
} */

.log_out {
  position: absolute;
  border-top: 0.2px solid #e5e7eb;
  bottom: 0;
  width: 100%;
}

/*Sidebar closing*/
.main-header.active ~ .main-doc nav {
  width: calc(100% - 50px);
  left: 50px;
}
.main-header.active ~ .main-doc {
  width: calc(100% - 50px);
  left: 50px;
}

.main-doc {
  position: relative;
  min-height: 100vh;
  background: #e5e7eb;
  width: calc(100% - 220px);
  left: 220px;
  transition: all 0.7s ease;
  padding-bottom: 60px;
}

.main-doc nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  align-items: center;
  position: fixed;
  width: calc(100% - 220px);
  left: 220px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.7s ease;
  margin-bottom: 82px;
}
.main-doc nav .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}
.main-doc nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.main-doc nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}
.main-doc nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 12px;
}
.main-doc nav .search-box .bx-search {
  position: absolute;
  height: 40px;
  width: 40px;
  background: #f7a072ff;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
  cursor: pointer;
}
.main-doc nav .profile-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
}
.main-doc nav .profile-details i {
  margin-right: 20px;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
}
.main-doc nav .profile-details img {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 20px;
}

/* main content*/
.main-doc .main-container {
  padding: 0 1rem;
  padding-top: 90px;
}
.main-doc .main-container .i_name {
  color: #444a53;
  padding: 30px 30px 0 30px;
  font-size: 24px;
  font-weight: 700;
}
.main-doc .main-container .values {
  padding: 30px 30px 0 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.main-doc .main-container .values .val_box {
  background: #fff;
  width: 230px;
  padding: 16px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  margin-right: 20px;
}

.main-doc .main-container .values .val_box i {
  font-size: 25px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-right: 15px;
}
.main-doc .main-container .values .val_box:nth-child(1) i {
  background: chartreuse;
}
.main-doc .main-container .values .val_box:nth-child(2) i {
  background: orangered;
}
.main-doc .main-container .values .val_box:nth-child(3) i {
  background: orange;
}
.main-doc .main-container .values .val_box:nth-child(4) i {
  background: purple;
}
.main-doc .main-container .values .val_box:nth-child(5) i {
  background: steelblue;
}
.main-doc .main-container .values .val_box:nth-child(6) i {
  background: rgb(49, 230, 49);
}
.main-doc .main-container .values .val_box:nth-child(7) i {
  background: steelblue;
}
.val_box h3 {
  font-size: 18px;
  font-weight: 600;
  color:#000
}
.val_box span {
  font-size: 15px;
  color: #828997;
}

/* Table opening*/
.board {
  width: 95%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 8px;
}
.board table {
  width: 100%;
  border-collapse: collapse;
}
tr {
  border-bottom: 1px solid #eef0f3;
}
thead td {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  background: #f9fafb;
  color: #000;
  text-align: start;
  padding: 15px;
}
tbody tr td {
  padding: 10px 15px;
}
.board table img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.board table h5 {
  font-weight: 600;
  font-size: 16px;
}
.board table p {
  font-weight: 400;
  font-size: 13px;
  color: #000;
  padding-top: 9px;
  /* text-align: center; */
}
.no-applicant{
  display: flex;
  justify-content: center;
  padding: 20px;
  border: 1px solid red;
}
/* .people_des p {
  font-weight: 400;
  font-size: 13px;
  color: #000;
  padding-top: 9px;
  text-align: center;
} */
.people {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
}
.people .people_des {
  margin-left: 12px;
  cursor: pointer;
}
.people .people_des h5:hover,
.people .people_des p:hover {
  text-decoration: underline;
}
.tab_edit p {
  padding: 12px 15px;
  display: inline-block;
  background: red;
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
}
.tab_role p {
  padding: 12px 15px;
  display: inline-block;
  background: #d7fada;
  border-radius: 8px;
  /* border-radius: 40px; */
  color: #2b2b2b;
  cursor: pointer;
}
.tab_delete p {
  display: inline-block;
  color: red;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
.tab_active a {
  font-size: 14px;
  color: #554cd1;
  font-weight: 600;
}
.pen_head {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0;
}
.pen_head h3 {
  font-size: 22px;
  color: #999;
}

/*modal*/
.modal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  right: -100%;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  transition: all 0.9s ease;
  display: flex;
  justify-content: flex-end;
}

.action {
  right: 0;
}
/* Modal Content/Box */

.modal-content {
  position: relative;
  background-color: #fefefe;
  border: 1px solid #888;
  width: 750px; /* Could be more or less, depending on screen size */
  height: 100%;
}
.noti_head {
  height: 80px;
  width: 100%;
  padding-left: 20px;
  border-bottom: 0.2px solid #e5e7eb;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noti_para {
  height: 40px;
  width: 100%;
  padding-left: 20px;
  border-bottom: 0.2px solid #e5e7eb;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.noti_cancel {
  font-size: 45px;
  color: red;
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;
}
.poti_cancel {
  font-size: 45px;
  color: red;
  position: absolute;
  top: 7px;
  display: none;
  margin: auto;
  cursor: pointer;
}
/*End of modal*/

.podal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  top: -100%;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  transition: all 0.9s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pac {
  top: 0;
}
/* Modal Content/Box */
.podal-content {
  position: relative;
  background-color: #fefefe;
  border: 1px solid #888;
  width: 760px; /* Could be more or less, depeFnding on screen size */
  height: 500px;
  overflow-y: auto;
}
.podal-content .split {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
}
.podal-content .split .left {
  width: 100%;
  padding: 20px 0 0 0;
}
.podal-content .split .left .msg-img {
  width: 250px;
  height: 250px;
  margin: auto;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5%;
}
.podal-content .split .left .pod_but {
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  justify-content: space-evenly;
}
.podal-content .split .left .pod_but button {
  padding: 12px 25px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}
.podal-content .split .left .pod_but button:nth-child(1) {
  background: #d7fada;
  color: #2b2b2b;
}
.podal-content .split .left .pod_but button:nth-child(2) {
  background: red;
  color: #fff;
}
.podal-content .split .right {
  width: 100%;
  padding: 20px 10px 10px 10px;
}
.podal-content .split .right .right_head {
  height: 30px;
  width: 100%;
  border-bottom: 0.2px solid #e5e7eb;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111;
  padding-bottom: 5px;
}
.podal-content .split .right .right_content {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}
.podal-content .split .right .right_content:nth-child(3) {
  background: #e5e7eb;
}
.podal-content .split .right .right_content:nth-child(5) {
  background: #e5e7eb;
}
.podal-content .split .right .right_content:nth-child(7) {
  background: #e5e7eb;
}
.podal-content .split .right .right_content:nth-child(9) {
  background: #e5e7eb;
}
.podal-content .split .right .right_content:nth-child(11) {
  background: #e5e7eb;
}
.podal-content .split .right .right_content:nth-child(13) {
  background: #e5e7eb;
}
.podal-content .split .right .right_content p {
  font-size: 16px;
  color: #222;
  font-weight: 500;
}
.podal-content .split .right .right_content .right_para {
  color: #666;
  font-size: 14px;
  font-weight: 400;
}
.poti_para {
  height: 40px;
  width: 100%;
  padding-left: 20px;
  border-bottom: 0.2px solid #e5e7eb;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.poti_cancel {
  font-size: 45px;
  color: red;
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;
}
/* loading style */
.isLoading{
  display: flex;
  justify-content: center;
  width: 100%;
  
}
.nav-link-back {
  padding: 26px;
}
.nav-link-back a {
    color: #354f52;
    font-size: 18px;
}

@media (max-width: 768px) {
  .main-header {
    position: fixed;
    width: 300px;
    left: -300px;
    z-index: 200;
  }
  .main-doc {
    width: 100%;
    left: 0;
  }
  .main-doc nav {
    width: 100%;
    left: 0;
  }
  .main-header.active {
    left: 0;
    z-index: 200;
  }
  .main-doc .main-container .i_name {
    padding: 15px 10px;
  }
  .main-doc .main-container .values {
    justify-content: space-evenly;
  }
  .main-doc .main-container .values .val_box {
    width: 260px;
  }
  .board {
    width: 95%;
    padding: 0;
    overflow-x: auto;
  }
  .board table {
    width: 100%;
    border-collapse: collapse;
  }
  .modal-content {
    width: 100%;
  }
  .podal-content {
    padding-top: 50px;
    width: 100%;
    height: 100%;
  }
  .poti_cancel {
    display: block;
  }
}

@media (max-width: 550px) {
  .main-header {
    position: fixed;
    width: 250px;
    left: -250px;
  }
  .main-doc .main-container .values {
    justify-content: center;
  }
  
  .main-doc nav {
    padding: 5px;
  }
  .i_name {
    padding: 30px 15px 0 15px;
  }
  .main-doc .main-container .values {
    padding: 0;
  }
  .main-doc .main-container .values .val_box {
    width: 100%;
    margin: 10px 0;
  }
  .board {
    margin: 30px 0;
  }
  .podal-content .split {
    grid-template-columns: 100%;
  }

}

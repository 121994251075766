.main-profile {
  padding: 0 1rem;
  padding-top: 105px;
}
.main-profile .i_name {
  color: #444a53;
  font-size: 24px;
  font-weight: 700;
}
.profile_cont {
  width: 100%;
  margin-top: 20px;
}
.profile_head {
  width: 100%;
  display: flex;
  justify-content: center;
}
.profile_head h4 {
  font-size: 25px;
  color: #222;
}
.profile_box {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 50% 50%;
}
.input_box {
  max-width: 500px;
  border-bottom: 0.5px solid #354f52;
}
.input_box p {
  font-size: 20px;
  color: grey;
  padding-bottom: 10px;
}
.input_box input[type='text'] {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 5px 10px;
  color: #111;
  background: transparent;
}
.input_box input[type='email'] {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 5px 10px;
  color: #111;
  background: transparent;
}
.input_box select {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 5px 10px;
  color: #111;
  background: transparent;
}
.input_box input[type='password'] {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 5px 10px;
  color: #111;
  background: transparent;
}
.input_box input[type='file'] {
  width: 100%;
  height: 40px;
  border-right: 0.5px solid #111;
  border-top: 0.5px solid #111;
  border-left: 0.5px solid #111;
  outline: none;
  color: #111;
  padding: 5px 10px;
}
.input_boxes {
  max-width: 500px;
}
.input_boxes p {
  font-size: 20px;
  color: grey;
  padding-bottom: 10px;
}
.input_boxes textarea {
  width: 100%;
  height: 120px;
  outline: none;
  font-size: 19px;
  padding: 2px 10px;
  color: #111;
  background: transparent;
  border: 0.5px solid #354f52;
  border-radius: 6px;

}
.per_button {
  margin: 25px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.per_button button {
  padding: 15px 60px;
  background: #354f52;
  color: #fff;
  outline: none;
  border: 1px solid #354f52;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: .5px;
}
.setting_close {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.setting_close i {
  font-size: 80px;
  color: #354f52;
  cursor: pointer;
  background: transparent;
}
.setting_close p {
  position: absolute;
  color: grey;
  top: -15px;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .input_box {
    max-width: 320px;
  }
  .setting_close i {
    font-size: 70px;
  }
}

@media (max-width: 550px) {
  .profile_box {
    grid-template-columns: 100%;
  }
  .input_box {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
  .per_button button {
    padding: 10px 25px;
  }
  .setting_close i {
    font-size: 50px;
  }
}
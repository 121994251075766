

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --black-coral: hsla(220, 12%, 43%, 1);
    --atomic-tangerine: #f7a072ff;
    --vanilla: #eddea4ff;
    --baby-powder: #f9f7f3ff;
}


html {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    color: var(--black-coral);
}

body {
    min-height: 100vh;
    /* background-color: var(--baby-powder); */

}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}

.container-form {
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 25px 30px;
    /* margin-top: 90px; */
  }

section {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: 17px;
    /* background-color: var(--vanilla); */

}

.logo-auth {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

/* a, a:visited {
    color: var(--black-coral);
} */

/* .button {
    height: 45px;
    margin: 35px 0;
  } */
 .button {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
  }

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label,
button {
  margin-top: 0.5rem;
}

button {
  padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    /* background: #000; */
    /* color: var(--black-coral); */
    color: red;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}


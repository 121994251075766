:root {
    /**
     * colors
     */
  
    --moonstone: #0fa3b1ff;
    --uranian-blue: #b5e2faff;
    --baby-powder: #f9f7f3ff;
    --vanilla: #eddea4ff;
    --atomic-tangerine: #f7a072ff;
    /* --hero: #353535; */
    --hero: #354f52;
    /* #354f52 */
  
    --violet-blue-crayola: hsla(234, 50%, 64%, 1);
    --dark-cornflower-blue_a7: hsla(214, 88%, 27%, 0.07);
    --white: hsla(0, 0%, 100%, 1);
    --white_a3: hsla(0, 0%, 100%, 0.03);
    --white_a8: hsla(0, 0%, 100%, 0.08);
    --white_a12: hsla(0, 0%, 100%, 0.12);
    --white_a70: hsla(0, 0%, 100%, 0.7);
    --cultured: hsla(220, 20%, 97%, 1);
    --lavender-web: hsla(233, 52%, 94%, 1);
    --cadet-blue-crayola: hsla(220, 12%, 70%, 1);
    --cadet-blue-crayola_a20: hsla(222, 23%, 71%, 0.2);
    --charcoal: hsla(218, 22%, 26%, 1);
    --raisin-black: hsla(216, 14%, 14%, 1);
    --light-gray: hsla(0, 0%, 79%, 1);
    --blue-crayola: hsla(219, 72%, 56%, 1);
    --black-coral: hsla(220, 12%, 43%, 1);
  
    /**
     * typography
     */
  
    --ff-manrope: "Manrope", sans-serif;
    /* --poppins: 'Poppins' , sans-serif; */
    /* font-family: 'Delicious Handrawn', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Rubik', sans-serif; */
  
    --fs-1: calc(2.7rem + 1.38vw);
    --fs-2: calc(2.6rem + 0.66vw);
    --fs-3: 2.2rem;
    --fs-4: 1.9rem;
    --fs-5: 1.8rem;
    --fs-6: 1.7rem;
    --fs-7: 1.5rem;
    --fs-8: 1.4rem;
  
    --fw-700: 700;
  
    /** 
     * spacing
     */
  
    --section-padding: 40px;
    /* --section-padding: 90px; */
  
    /**
     * box shadow
     */
  
    --shadow-1: 0 0 20px hsla(216, 14%, 14%, 0.05);
    --shadow-2: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08),
      0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    --shadow-3: 0 0 1.25rem hsla(216, 14%, 14%, 0.04);
  
    /**
     * border radius
     */
  
    --radius-circle: 50%;
    --radius-pill: 100px;
    --radius-10: 10px;
    --radius-8: 8px;
    --radius-6: 6px;
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --transition-3: 0.3s ease-in-out;
  }
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li {
    list-style: none;
  }
  
  a,
  img,
  span,
  input,
  button,
  ion-icon {
    color: var(--atomic-tangerine);
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    height: auto;
  }
  
  input,
  button {
    background: none;
    /* border: none; */
    font: inherit;
  }
  
  input {
    width: 100%;
  }
  
  button {
    cursor: pointer;
  }
  
  ion-icon {
    pointer-events: none;
  }
  
  address {
    font-style: normal;
  }
  
  html {
    font-family: var(--ff-manrope);
    font-size: 10px;
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--baby-powder);
    /* background-color: var(--white); */
    color: var(--black-coral);
    /* line-height: 1.7; */
  }
  
  body.nav-active {
    overflow: hidden;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: hsl(0, 0%, 98%);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 80%);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0, 0%, 70%);
  }
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  .container {
    padding-inline: 16px;
  }
  
  .social-list {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .social-link {
    font-size: 2rem;
    transition: var(--transition-1);
  }
  
  .social-link:is(:hover, :focus-visible) {
    transform: translateY(-3px);
  }
  
  .section {
    padding-block: var(--section-padding);
  }
  /* .section {
    margin-top: 73px;
  } */
  
  .items-center {
    max-width: 745px;
    align-items: center;
    text-align: center;
  }
  
  .items-center .section-text {
    font-size: 16px;
  }
  
  .items-centr {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .has-bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    color: var(--charcoal);
    font-weight: var(--fw-700);
    line-height: 1.3;
  }
  
  .h1 {
    font-size: var(--fs-1);
  }
  
  .h2 {
    font-size: var(--fs-2);
  }
  
  .h3 {
    font-size: var(--fs-3);
  }
  
  .h4 {
    font-size: var(--fs-4);
  }
  
  .h5 {
    font-size: var(--fs-6);
  }
  
  .btn {
    color: var(--white);
    font-weight: var(--fw-700);
    border: 2px solid var(--white);
    max-width: max-content;
    padding: 6px 28px;
    background: var(--black-coral);
    border-radius: var(--radius-8);
    transition: var(--transition-1);
    will-change: transform;
  }
  
  .btn:is(:hover, :focus-visible) {
    transform: translateY(-4px);
  }
  
  .btn-primary,
  .btn-outline:is(:hover, :focus-visible) {
    background-color: var(--white);
    color: var(--charcoal);
  }
  
  .img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
    overflow: hidden;
  }
  
  .img-cover {
    width: 100%;
    height: 434px;
    object-fit: cover;
  }
  .img-coverr {
    object-fit: cover;
    width: 199px;
  }
  
  .section-subtitle {
    font-size: var(--fs-3);
    text-transform: uppercase;
    color: var(--moonstone);
    font-weight: var(--fw-700);
    margin-block-end: 16px;
  }
  
  .grid-list {
    display: grid;
    gap: 25px;
  }
  
  .w-100 {
    width: 90%;
  }
  
  /*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/
  
  .header .btn {
    display: none;
  }
  
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-block: 20px;
    background: var(--black-coral);
    box-shadow: var(--shadow-1);
    z-index: 4;
    z-index: 100;
  }
  
  .header.active {
    background-color: var(--white);
    position: fixed;
    animation: slideIn 0.5s ease forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  .header.active .logo-light,
  .header .logo-dark {
    display: none;
  }
  
  .header .logo-light,
  .header.active .logo-dark {
    display: block;
  }
  
  .nav-open-btn {
    color: var(--white);
    font-size: 2rem;
  }
  
  .header.active .nav-open-btn {
    color: var(--charcoal);
  }
  
  .navbarr {
    position: fixed;
    top: 0;
    left: -300px;
    background-color: var(--black-coral);
    /* background-color: var(--raisin-black); */
    color: var(--white);
    max-width: 300px;
    width: 100%;
    height: 100vh;
    padding: 30px;
    padding-block-end: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 2;
    visibility: hidden;
    transition: var(--transition-3);
  }
  
  .navbarr.active {
    visibility: visible;
    transform: translateX(300px);
  }
  
  .navbarr-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-close-btn {
    font-size: 2rem;
  }
  
  .nav-close-btn ion-icon {
    --ionicon-stroke-width: 50px;
  }
  
  .nav-close-btn:is(:hover, :focus-visible) {
    background-color: var(--white_a12);
  }
  
  .navbarr-list {
    margin-block-end: auto;
    line-height: 2.6rem;
  }
  
  .navbarr-link {
    font-weight: var(--fw-700);
    padding-block: 6px;
  }
  
  .contact-link {
    transition: var(--transition-1);
  }
  
  .contact-link:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--raisin-black);
    pointer-events: none;
    opacity: 0;
    transition: var(--transition-1);
  }
  
  .overlay.active {
    pointer-events: all;
    opacity: 0.8;
  }
  
  /*-----------------------------------*\
    #HERO
  \*-----------------------------------*/
  
  .hero {
    /* padding-block-start: calc(var(--section-padding) + 70px); */
    /* padding-block-start: calc(var(--section-padding) + 3px); */
    text-align: center;
  }
  
  .hero .container {
    display: grid;
    gap: 70px;
    margin-top: 20px;
    /* max-height: 350px; */
  }
  
  .hero-title {
    font-size: 80px;
    color: var(--white);
  }
  
  .hero-text-header {
    font-size: var(--fs-5);
    color: var(--white);
    margin-bottom: 0px;
  }
  
  .hero-text {
    font-size: var(--fs-5);
    color: var(--white);
    margin-block: 0px 12px;
  }
  
  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  
  .hero-slider,
  .hero-card {
    position: relative;
  }
  
  .hero-card .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    color: var(--charcoal);
    font-size: 2rem;
    padding: 25px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
    animation: pulse 2s ease infinite;
  }
  
  .hero-card .play-btn:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--white_a70);
    }
    75% {
      box-shadow: 0 0 0 20px transparent;
    }
  }
  
  .hero .slider-inner {
    border-radius: var(--radius-10);
    overflow: hidden;
  }
  
  .hero .slider-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    transition: var(--transition-2);
  }
  
  .hero .slider-item {
    min-width: 100%;
    width: 100%;
    border-radius: var(--radius-10);
    overflow: hidden;
  }
  
  .hero .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background-color: var(--white_a70); */
    color: var(--charcoal);
    font-size: 2rem;
    /* padding: 12px; */
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
  }
  
  .hero .slider-btn:is(:hover, :focus-visible) {
    background-color: var(--white);
  }
  
  .hero .slider-btn.prev {
    left: 20px;
  }
  
  .hero .slider-btn.next {
    right: 20px;
  }
  
  /*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/
  
  .service {
    text-align: center;
  }
  
  .service .section-title {
    margin-block-end: 50px;
  }
  
  .service-card {
    padding: 17px;
    min-height: 322px;
    box-shadow: var(--shadow-2);
    border-radius: var(--radius-6);
  }
  
  .service-card .card-icon {
    width: 88px;
    height: 82px;
    /* border: 3px solid var(--); */
    display: grid;
    background-color: var(--white);
    place-items: center;
    color: var(--white);
    font-size: 2.5rem;
    border-radius: var(--radius-circle);
    margin-inline: auto;
  }
  
  .card-icon img {
    width: 84px;
    height: 75px;
  }
  
  .card {
    background-color: var(--cultured);
    border: none;
  }
  
  .service-card .card-icon ion-icon {
    --ionicon-stroke-width: 50px;
  }
  
  .service-card .card-title {
    margin-block: 16px 10px;
  }
  
  .service-card .btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-block-start: 10px;
    color: var(--atomic-tangerine);
    font-weight: var(--fw-700);
    transition: var(--transition-1);
  }
  
  .service-card .btn-text:is(:hover, :focus-visible) {
    opacity: 0.9;
  }
  
  /*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/
  
  .about .container {
    display: grid;
    gap: 50px;
  }
  
  .about-item {
    margin-bottom: 18px;
  }
  
  .about .section-title {
    margin-block-end: 35px;
  }
  
  .accordion-card .card-title {
    padding-block-end: 20px;
  }
  
  .accordion-btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .accordion-btn ion-icon {
    font-size: 1.5rem;
    border: 1px solid;
    color: var(--atomic-tangerine);
    transition: var(--transition-1);
  }
  
  .accordion-card.expanded .accordion-btn ion-icon {
    transform: rotate(0.5turn);
  }
  
  .accordion-btn .span {
    transition: var(--transition-1);
  }
  
  .accordion-btn:is(:hover, :focus-visible) .span,
  .accordion-card.expanded .accordion-btn .span {
    color: var(--atomic-tangerine);
  }
  
  .accordion-content {
    padding-inline-start: 24px;
    max-height: 0;
    overflow: hidden;
  }
  
  .accordion-card.expanded .accordion-content {
    max-height: max-content;
    padding-block-end: 20px;
  }
  
  /*-----------------------------------*\
    #FEATURES
  \*-----------------------------------*/
  
  .feature .container {
    display: grid;
    gap: 50px;
  }
  .feature .items-center {
    display: row;
    gap: 50px;
  }
  
  .feature .section-text {
    margin-block: 25px 30px;
  }
  
  .feature-list {
    display: grid;
    gap: 15px;
  }
  
  .feature-card {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .feature-card .card-icon {
    background-color: var(--lavender-web);
    font-size: 1.4rem;
    padding: 4px;
    border-radius: var(--radius-circle);
  }
  
  /* .feature-content {
    text-align: center; 
  } */
  
  .feature-content h2 {
    margin-bottom: 40px;
  }
  
  .feature-card .card-icon ion-icon {
    --ionicon-stroke-width: 40px;
  }
  
  /*-----------------------------------*\
    #STATS
  \*-----------------------------------*/
  
  /* .stats { background-image: linear-gradient(to bottom, var(--white) 50%, var(--cultured) 50%); } */
  
  .stats-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 36px 24px;
    padding: 45px 30px;
    border-radius: var(--radius-8);
  }
  
  .stats-card .card-text > * {
    display: block;
    color: var(--white);
  }
  
  /*-----------------------------------*\
    #PROJECT
  \*-----------------------------------*/
  
  .project {
    background-color: var(--cultured);
  }
  
  .project :is(.section-subtitle, .section-title) {
    text-align: center;
  }
  
  /* .project .section-title { margin-block-end: 50px; } */
  
  .project-card {
    background-color: var(--white);
    /* border-radius: var(--radius-8); */
    border-radius: 40px;
    overflow: hidden;
    box-shadow: var(--shadow-2);
    height: 100%;
  }
  
  .project-card .card-content {
    padding: 30px;
  }
  
  .project-card .card-title {
    transition: var(--transition-1);
  }
  
  .project-card .card-title:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
  }
  
  .project-card .card-text {
    margin-block: 16px 20px;
  }
  
  .project-card .card-meta-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .project-card .card-meta-item {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--cadet-blue-crayola);
    font-size: var(--fs-8);
  }
  
  /*-----------------------------------*\
    #CTA
  \*-----------------------------------*/
  
  .cta {
    background-color: var(--charcoal);
  }
  
  .cta .container {
    padding-block: 100px 60px;
    border-block-end: 1px solid var(--cadet-blue-crayola_a20);
  }
  
  .cta .section-title {
    color: var(--white);
    margin-block-end: 30px;
  }
  
  .cta .btn {
    background-color: var(--black-coral);
    color: var(--white);
    border: none;
  }
  
  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/
  
  .footerr {
    background-color: var(--hero);
    padding-block: 60px 34px;
    color: white;
  }
  
  .footerr .consider {
    color: var(--atomic-tangerine);
  }
  
  .footer-brand .footer-text {
    margin-block: 20px;
  }
  
  .footer .social-list {
    color: var(--white);
    margin-bottom: 30px;
  }
  
  .footer-list-title {
    color: var(--white);
    margin-block-end: 16px;
  }
  
  .footer-link {
    padding-block: 4px;
    transition: var(--transition-1);
  }
  
  .footer-link:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
  }
  
  .input-wrapper {
    position: relative;
    margin-block-start: 25px;
  }
  
  .footer {
    background-color: var(--charcoal);
    padding-block: 60px 34px;
    color: var(--light-gray);
  }
  
  .footer-brand .footer-text {
    margin-block: 20px;
  }
  
  .footer .social-list {
    color: var(--white);
    margin-bottom: 30px;
  }
  
  .footer-list-title {
    color: var(--white);
    margin-block-end: 16px;
  }
  
  .footer-link {
    padding-block: 4px;
    transition: var(--transition-1);
  }
  
  .footer-link:is(:hover, :focus-visible) {
    color: var(--violet-blue-crayola);
  }
  
  .input-wrapper {
    position: relative;
    margin-block-start: 25px;
  }
  
  .input-field {
    background-color: var(--white_a3);
    color: var(--light-gray);
    font-size: var(--fs-7);
    padding: 12px 16px;
    border: 1px solid var(--dark-cornflower-blue_a7);
    border-radius: var(--radius-6);
    box-shadow: var(--shadow-3);
    outline: none;
  }
  
  .input-field::placeholder {
    color: inherit;
  }
  
  .submit-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 var(--radius-6) var(--radius-6) 0;
    background-color: var(--black-coral);
    color: var(--white);
    padding-inline: 24px;
    font-weight: var(--fw-700);
  }
  
  /*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/
  
  /**
   * responsive for large than 575px screen
   */
  
  @media (min-width: 575px) {
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 540px;
      width: 100%;
      margin-inline: auto;
    }
  
    /**
     * PROJECTS
     */
  
    .project-card .card-content {
      padding: 40px;
    }
  }
  
  /**
   * responsive for large than 768px screen
   */
  
  @media (min-width: 768px) {
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
      /**
       * spacing
       */
  
      /* --section-padding: 120px; */
      --section-padding: 70px;
    }
  
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 720px;
    }
    .custom-select {
      max-width: 216px;
    }
  
    .grid-list {
      grid-template-columns: 1fr 1fr;
      padding: 60px;
    }
  
    /**
     * HEADER
     */
  
    .header .btn {
      display: block;
      margin-inline-start: auto;
      padding: 4px 20px;
    }
  
    .header.active .btn {
      background-color: var(--black-coral);
      border-color: var(--black-coral);
      color: var(--white);
    }
  
    /**
     * HERO
     */
  
    .hero-content {
      max-width: 85%;
      margin-inline: auto;
    }
  
    .hero-text {
      --fs-5: 2rem;
    }
  
    /**
     * SERVICE
     */
  
    .service .section-title {
      max-width: 30ch;
      margin-inline: auto;
    }
  
    /**
     * ABOUT
     */
  
    .about .container {
      grid-template-columns: 1fr 0.7fr;
      align-items: center;
    }
  
    /**
     * FEATURE
     */
  
    .feature .container {
      grid-template-columns: 0.9fr 1fr;
      align-items: center;
    }
  
    .feature-banner {
      order: 1;
    }
  }
  
  /**
   * responsive for large than 992px screen
   */
  
  /* @media (min-width: 992px) { */
  @media (min-width: 1200px) {
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 960px;
    }
  
    /**
     * HEADER
     */
  
    .nav-open-btn,
    .navbarr > *:not(.navbarr-list),
    .overlay {
      display: none;
    }
  
    .navbarr,
    .navbarr.active {
      all: unset;
      display: block;
      margin-inline: auto 24px;
    }
  
    .navbarr-list {
      display: flex;
      gap: 30px;
    }
  
    .navbarr-link {
      color: var(--white);
      transition: var(--transition-1);
    }
  
    .navbarr-link:is(:hover, :focus-visible) {
      opacity: 0.7;
    }
  
    .header.active .navbarr-link {
      color: var(--charcoal);
    }
  
    .header.active .navbarr-link:is(:hover, :focus-visible) {
      opacity: 1;
      color: var(--violet-blue-crayola);
    }
  
    .header .btn {
      margin-inline-start: 0;
    }
  
    /**
     * HERO
     */
  
    .hero {
      /* padding-block-start: calc(var(--section-padding) + 50px); */
      text-align: left;
    }
  
    .hero .container {
      grid-template-columns: 1fr 0.8fr;
      /* align-items: center; */
    }
  
    .hero-content {
      max-width: unset;
      margin-inline: 0;
    }
  
    .btn-wrapper {
      justify-content: flex-start;
    }
  
    /**
     * STATS
     */
  
    .stats-card {
      grid-template-columns: repeat(4, 1fr);
    }
  
    /**
     * PROJECT
     */
  
    .project .section-title {
      max-width: 32ch;
      margin-inline: auto;
    }
  
    /**
     * CTA
     */
  
    .cta .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .cta .btn {
      min-width: max-content;
    }
  
    .cta .section-title {
      max-width: 30ch;
      margin-block-end: 0;
    }
  
    /**
     * FOOTER
     */
  
    .footer .grid-list {
      grid-template-columns: repeat(4, 1fr);
    }
    .footerr .grid-list {
      grid-template-columns: repeat(3, 1fr);
    }
  
    .footerr .grid-listt {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /**
   * responsive for large than 1200px screen
   */
  
  @media (min-width: 1200px) {
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 1140px;
    }
  
    /**
     * HERO
     */
  
    .hero-text {
      padding-inline-end: 100px;
    }
  
    /**
     * SERVICE
     */
  
    .service .grid-list {
      grid-template-columns: repeat(4, 1fr);
    }
    .service .grid-listt {
      grid-template-columns: repeat(3, 1fr);
    }
  
    /**
     * STATS
     */
  
    .stats .container {
      max-width: 100%;
    }
  
    .stats-card {
      padding: 60px;
    }
  
    /**
     * PROJECT
     */
  
    .project .grid-list {
      grid-template-columns: repeat(3, 1fr);
    }
  
    /**
     * FOOTER
     */
  
    address.footer-text {
      padding-inline-end: 100px;
      margin-block-end: 16px;
    }
  }
  
  /**
   * responsive for large than 1400px screen
   */
  
  @media (min-width: 1400px) {
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 1320px;
    }
  
    /**
     * FEATURE
     */
  
    .feature-list {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .maincontainer {
    position: relative;
    min-width: 305px;
    height: 412px;
  }
  
  .thecard {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
  }
  
  .maincontainerr {
    position: relative;
    height: 70px;
  }
  
  .thecardd {
    position: absolute;
    /* width: 100%;
    height: 100%; */
    transform-style: preserve-3d;
    transition: all 0.5s ease;
  }
  
  .thecard:hover {
    transform: rotateY(180deg);
  }
  
  .thefront {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: #ffc728;
    color: #333;
    text-align: center;
    border-radius: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .theback {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: var(--atomic-tangerine);
    color: #333;
    transform: rotateY(180deg);
    padding: 30px;
    border-radius: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .theback .btn {
    margin-top: 40px;
    border-radius: 10px;
    background-color: var(--baby-powder);
    display: flex;
    align-items: center;
    margin: 20px;
    /* justify-content: space-between; */
  }
  
  .btn-about {
    color: var(--white);
  }
  
  .hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
  }
  
  .show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
  
  .card-list:nth-child(2) {
    transition-delay: 200ms;
  }
  .card-list:nth-child(3) {
    transition-delay: 400ms;
  }
  .card-list:nth-child(4) {
    transition-delay: 600ms;
  }
  
  /* Swipper Js */
  swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  
  swiper-slide img {
    display: block;
    width: 100%;
  }
  
  /* .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-gap: 10px; 
  } */
  
  /* TESTIMONIALS */
  .contain {
    width: 100%;
    /* background: #ffe8f0; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .testimonial {
    width: 90%;
    max-width: 1088px;
    margin: auto;
  }
  
  .testimon .container {
    text-align: center;
  }
  
  .testimonial-text {
    background: var(--hero);
    color: #fff;
    width: 100%;
    border-radius: 10px;
    height: 350px;
    position: relative;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  }
  
  @media (min-height: 659px) {
    .user-text {
      font-size: 15.5px;
    }
    
  }
  
  .user-text {
    width: 80%;
    text-align: center;
    /* line-height: 30px; */
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  
  .user-text.active-text {
    display: block;
    animation: moveup 0.5s linear forwards;
  }
  
  .user-pic.active-pic {
    width: 100px;
    /* width: 71px; */
    border: 3px solid var(--atomic-tangerine);
  }
  
  .user-text .fas {
    font-size: 30px;
    margin-bottom: 24px;
    margin-top: 20px;
  }
  
  .user-text span {
    display: block;
    font-size: 13px;
    margin-top: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .testimonial-pic {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }
  
  .user-pic {
    width: 7%;
    padding: 5px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
  }
  
  @keyframes moveup {
    0% {
      margin-top: 25px;
    }
    100% {
      margin-top: 0px;
    }
  }
  
  .ad-div {
    margin-top: 46px;
  }
  
  .ad-img {
    width: 518px;
    height: 241px;
  }
  
  @media (max-width: 768px) {
    .ad-img {
      width: 393px;
    }
   
  }
  @media (max-width: 568px) {
    .ad-img {
      width: 362px;
    }
   
  }
  
  .avatar {
    top: 0px;
    left: 342px;
  }
  .avatar-hero {
    height: 16%;
    width: 20%;
    background: bisque;
    border-radius: 100px;
    position: relative;
    top: -66px;
    left: 181px;
  }
  
  .container-form {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    /* position: relative;
    top: 121px; */
    padding: 25px 30px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  .containerr-form {
    max-width: 355px;
    width: 100%;
    height: 539px;
    background-color: #fff;
    /* position: relative;
    top: 121px; */
    padding: 25px 30px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  .containerr-form .title {
    font-size: 25px;
    font-weight: 500;
    position: relative;
    top: -40px;
  }
  .containerr-form .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
  }
  
  .container-form .title {
    font-size: 25px;
    font-weight: 500;
    position: relative;
    top: -40px;
  }
  .container-form .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
  }
  .content form .user-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin: 20px 0 12px 0; */
  }
  form .user-details .input-box {
    position: relative;
    top: -25px;
    margin-bottom: 15px;
    width: calc(100% / 2 - 20px);
  }
  form .input-box span.details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .user-details .input-box input {
    background: transparent;
    border-style: solid;
    border-color: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
    border-right: none;
    border-top: none;
    border-left: none;
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    padding-left: 15px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
  }
  .user-details .input-box input:focus,
  .user-details .input-box input:valid {
    background: transparent;
    border-style: solid;
    border-color: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
    border-top: none;
    border-right: none;
    border-bottom: none;
  }
  
  #note {
    min-width: 478px;
    height: 114px;
  }
  
  .note {
    min-width: 278px;
    height: 114px;
  }
  
  #country {
    width: 189px;
    margin-top: 14px;
    height: 32px;
  }
  
  /* @media (min-height: 574px) {
    .study-enquiry {
      min-height: 956px;
    }
  } */
  @media (min-height: 575px) {
    .study-enquiry {
      min-height: 796px;
    }
  }
  
  /* .study-enquiry {
    min-height: 1153px;
  } */
  
  form .category {
    display: flex;
    width: 80%;
    margin: 14px 0;
    justify-content: space-between;
  }
  form .category label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  form .button {
    height: 45px;
    /* margin: 35px 0; */
  }
  form .button input {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
  }
  form .button input:hover {
    /* transform: scale(0.99); */
    background: linear-gradient(-135deg, tomato, #71b7e6, #9b59b6, cyan);
  }
  @media (max-width: 584px) {
    .container {
      max-width: 100%;
    }
  
    .containerr-form {
      height: 649px;
    }
  
    form .user-details .input-box {
      margin-bottom: 15px;
      width: 100%;
    }
    form .category {
      width: 100%;
    }
    .content form .user-details {
      max-height: 300px;
      overflow-y: scroll;
    }
    .user-details::-webkit-scrollbar {
      width: 5px;
    }
    #note {
      min-width: 378px;
      height: 114px;
    }
   
  }
  @media (max-width: 459px) {
    .container .content .category {
      flex-direction: column;
    }
    .avatar-hero {
      display: none;
    }
    .containerr-form .title {
      top: -18px;
    }
    form .button {
      height: 45px;
      margin: 35px 0;
    }
    .hero-title {
      font-size: 43px;
      /* color: var(--white); */
  }
  #note {
    min-width: 247px;
    height: 114px;
  }
  }
  
  .study-title {
    text-align: center;
  }
  
  .study-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }
  
  .study-containerr {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .auto-type {
    font-family: "Courier New", monospace;
    /* font-size: 50px; */
    font-weight: bold;
    /* animation: typing 3s steps(40) infinite; */
    white-space: nowrap;
    overflow: hidden;
  }
  
  /* TO TOP */
  
  .to-top {
    background: var(--black-coral);
    position: fixed;
    bottom: 16px;
    right: 32px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    font-size: 32px;
    color: var(--baby-powder);
    opacity: 0;
    text-decoration: none;
    pointer-events: none;
    transition: all 0.4s;
  }
  .to-top.active {
    bottom: 32px;
    pointer-events: auto;
    opacity: 1;
  }
  .to-top:hover {
    opacity: 0.8;
    color: var(--atomic-tangerine);
  }
  
  .popup {
    background: var(--baby-powder);
    border-radius: 6px;
    position: relative;
    top: 50%;
    left: 30%;
    max-width: 480px;
    transform: translate(-50%, -50%) scale(0.1);
    text-align: center;
    padding: 0 30px 0px;
    color: #333;
    visibility: hidden;
    transition: transform 0.4s, top 0.4s;
  }
  
  .popup button {
    width: 100%;
  }
  
  .open-popup {
    visibility: visible;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  
  .popup h2 {
    font-size: 38px;
    font-weight: 500;
    padding: 10px 0;
    background: #6fd649;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .flex-container {
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
  }
  
  .items-centrr {
    /* display: flex;
      align-items: center; */
    padding: 30px;
    max-width: 540px;
  }
  
  /* PARTNER SHCOOL CONTAINER */
  /* .containerr .roww {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .row {
    display: flex;
  } */
  
  .swiper {
    width: 100%;
  }
  
  .swiper-wrapper {
    width: 100%;
    height: 19em;
    align-items: center;
    display: flex;
  }
  
  /* / */
  .card {
    width: 20em;
    height: 50%;
    background-color: #fff;
    border-radius: 2em;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);
    padding: 2em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 2em;
  }
  
  .school-logo {
    width: 297px;
  }
  
  .swiper-slide:not(.swiper-slide-active) {
    filter: blur(1px);
  }
  
  .card__image {
    width: 10em;
    height: 10em;
    border-radius: 50%;
    border: 5px solid var(--atomic-tangerine);
    pad: 3px;
    margin-bottom: 2em;
  }
  
  .card__image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .card__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .card__title {
    font-size: 1.5rem;
    font-weight: 500;
    position: relative;
    top: 0.2em;
  }
  
  .card__name {
    color: var(--atomic-tangerine);
  }
  
  .card__text {
    text-align: center;
    font-size: 1.1rem;
    margin: 1em 0;
  }
  
  .card__btn {
    background-color: var(--atomic-tangerine);
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    padding: 0.5em;
    cursor: pointer;
  }
  
  .consider {
    text-align: center;
    padding: 20px;
  }
  
  .container .display-flex {
    display: flex;
  }
  
  
  .custom-select {
    display: inline-block;
    position: relative;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: var(--black-coral);
    border: 1px solid var(--atomic-tangerine);;
    border-radius: 4px;
    max-width: 286px;
    padding: 8px 0px 13px 10px;
    background-color: #fff;
    cursor: pointer;
  }
  .custom-selectt {
    display: inline-block;
    position: relative;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: var(--black-coral);
    border: 1px solid var(--atomic-tangerine);;
    border-radius: 4px;
    max-width: 199px;
    padding: 8px 0px 13px 10px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .custom-select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #555;
  }
  .custom-selectt:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #555;
  }
  
  .custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    cursor: pointer;
  }
  .custom-selectt select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    cursor: pointer;
  }
  
  /* Additional styles for hover and focus states */
  .custom-select:hover,
  .custom-select:focus {
    border-color: #888;
  }
  

.study-form-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .study-form-logo img {
    max-width: 200px; /* Adjust the logo size as needed */
  }

  .address, .contact {
    margin-top: 10px; /* Add some space between the address and contact numbers */
    padding: 0 20px;
  }

  .form-container {
    padding: 0 2rem;
  }

  .form-contact {
    text-align: center;
    margin-bottom: 36px;
  }
  
  .form-heading {
    text-align: center; /* Center the heading horizontally */
    font-size: 24px; /* Adjust the font size as needed */
    font-weight: bold; /* Add bold style to the heading */
  }

.form__container {
    max-width: 138rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.2rem;
    padding: 4.8rem 2rem;
}
.primary-form, .secondary-form {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1.4rem;
    padding-bottom: 2.8rem;
    border-radius: .25em;
}
.secondary-form {
    align-self: start;
}
.primary-info {
    background-color: rgba(11, 1, 70, 0.2);
    color: #333;
    display: inline-block;
    margin-bottom: 1.8rem;
    padding: .8rem 1.6rem;
    font-size: 1.4rem;
    border-radius: 10rem;
}
.primary-form, .secondary-form,
.form__input:focus, .form__textarea:focus {
    box-shadow: 0 0 .6rem rgba(0, 0, 0, .15);
}
.form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.form__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.2rem;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: .6rem;
}



@media  (max-width: 968px) {
    .form__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2.2rem;
    }
}

@media  (max-width: 668px) {
    .form__container {
        grid-template-columns: 1fr;
    }
}


.form__label {
    font-size: 1.4rem;
    margin-top: 14px;
    color: #848484;
}

.gender-check {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form__input, select {
    padding: .8rem;
    border-radius: .8rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    color: #848484;
    transition: all .15s;
}
.form__input::placeholder {
    color: #8484846e;
}
.form__input:focus, select:focus {
    border: 1px solid rgba(0, 0, 0, 0.125);
    outline: none;
}
.form__item span {
    font-size: 1.2rem;
    display: flex;
    gap: .6rem;
    color: #848484;
}
.form__textarea {
    width: 100%;
    resize: none;
    height: 12rem;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: .6rem;
    transition: all .15s;
}
.form__textarea::placeholder {
    font-size: 1.2rem;
    font-family: inherit;
    color: #84848475;
}
.form__textarea:focus {
    border: 1px solid rgba(0, 0, 0, 0.125);
    outline: none;
}
.form-check {
    border: 1px solid rgba(0, 0, 0, 0.125);;
    width: 12rem;
    height: 3.2rem;
    padding: .2rem .4rem;
    border-radius: .4rem;
    cursor: pointer;
    margin-right: 30px;
    display: flex;
    align-items: center;
    gap: .8rem;
}

.form-check-input {
    width: 50px;
    cursor: pointer;
}

.country-code {
    width: 309px;
}

.form-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}



.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, cyan, #71b7e6, #9b59b6, tomato);
}

.button input {
    cursor: pointer;
    color: #fff;
}
